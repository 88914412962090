import { HydrateOption } from '@core/enums';
import { aspectRatio } from '@helpers/aspectRatio';
import { imageUrl } from '@helpers/cloudinary';
import { Grid } from '@helpers/grid';
import withWidget from '@hoc/withWidget';
import { LinkButton } from '@stories/Components/Buttons/Button/Button';
import Heading from '@stories/Components/Global/Typography/Heading';
import Paragraph from '@stories/Components/Global/Typography/Paragraph';
import Iframe from '@stories/Components/Media/Iframe/Iframe';
import Image from '@stories/Components/Media/Image/Image';
import React from 'react';
import { MediaPromoStyles } from './MediaPromo.styles';
const MediaPromo = (props) => {
    return (React.createElement(MediaPromoStyles.Wrapper, null,
        React.createElement(MediaPromoStyles.Background, null, props.backgroundImageUrl && props.backgroundImageUrl.length && (React.createElement(Image, { src: props.backgroundImageUrl, layout: "fill", objectFit: "cover", objectPosition: "center center", role: "presentation" }))),
        React.createElement(Grid.Default, null,
            React.createElement(MediaPromoStyles.Media, null,
                props.videoUrl && (React.createElement(Iframe, { title: props.title || props.description || '', aspectRatio: aspectRatio(1920, 1080), url: props.videoUrl })),
                props.imageUrl && (React.createElement(Image, { layout: "responsive", width: 1116, height: 628, objectFit: "cover", src: props.imageUrl, alt: props.title || props.description || '' }))),
            React.createElement(MediaPromoStyles.Content, null,
                props.logoUrl && (React.createElement("img", { src: imageUrl(props.logoUrl, `c_fill,w_323`), alt: props.title || props.description || '' })),
                props.title && props.title.length && (React.createElement(Heading, { semantic: "h2", visual: "h4" }, props.title)),
                React.createElement(Paragraph, { size: "regular" }, props.description),
                React.createElement(LinkButton, { title: props.buttonText, href: props.buttonUrl, branding: "primaryAlt", target: props.buttonTarget })))));
};
export default withWidget(MediaPromo, 'MediaPromo', { hydrate: HydrateOption.InView });
