import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import { ImageStyles } from '@stories/Components/Media/Image/Image.styles';
import styled from 'styled-components';
const Wrapper = styled.div `
  margin: 40px 0px;
  padding: 10vw 0px;
  position: relative;
  color: ${brand.black};
`;
const Background = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${ImageStyles.Image} {
    clip-path: polygon(0 0, 100% 20%, 100% 80%, 0% 100%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(244, 244, 244, 0.01) 0%,
      rgba(244, 244, 244, 1) 100%
    );
    clip-path: polygon(0 0, 100% 20%, 100% 80%, 0% 100%);
  }
`;
const Media = styled.div `
  grid-column: span 12;

  @media ${from(Device.TabletLarge)} {
    grid-column: span 6;
  }
`;
const Content = styled.div `
  grid-column: span 12;
  align-self: center;
  position: relative;
  margin-top: 30px;

  @media ${from(Device.TabletLarge)} {
    grid-column: 8 / span 5;
    margin-top: 0px;
  }

  @media ${from(Device.DesktopLarge)} {
    grid-column: 8 / span 4;
  }

  > img {
    margin-bottom: 30px;
    max-width: 323px;
    width: 100%;
    display: block;
  }

  ${ParagraphStyles.Paragraph} {
    margin-bottom: 30px;
  }
`;
export const MediaPromoStyles = {
    Wrapper,
    Background,
    Media,
    Content,
};
